import React, { useState, useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes, Link } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import Header from "./common/Header";
import Footer from "./common/Footer";

const MainPage = () => {
  return (
    <div className="page">
      <div className="background bgWrapper">
        
        <header className="headerWrapper">
          <Header />
        </header>
        <div className="content-sec">
            <Outlet />
        </div>
        <div className="footer-sec">
          <div className="footer-wrapper">
            <Footer />
          </div>
        </div>
       


      </div>
    </div>
  );
};

export default MainPage;
