import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Ecommerce from '../assets/images/tools/ecommerce.png';
import HealthCare from '../assets/images/tools/healthcare.png';
import Government from '../assets/images/tools/government.png';
import Manufacturing from '../assets/images/tools/manufacturing.png';
import MobileApp from '../assets/images/mobile-app.png';
import WebApp from '../assets/images/web-app.png';
import EommerceApp from '../assets/images/e-commerce.png';
import AIApp from '../assets/images/AI-app.png';
import Designservice from '../assets/images/design-service.png';
import IOT from '../assets/images/IoT-app.png';

const Services = () =>{
    const [value, setValue] = useState();
    return(
        <div className='Services'>
                <div className="site-pages service">
                    <div className="container">
                        <div className="sec-bottom">
                            <p className="top-title">SERVICES</p>
                            <h2 className='sub-title'>Your Partner Across 
                            <br /> Industries And Businesses</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='our-services'>
                        <div className='row'>
                            <div className='col-md-4 pr-0'>
                                <div className='service-box'>
                                    <img src={WebApp} alt='' />
                                    <div className='overlay'>
                                        <h2>WebApp Development</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 pr-0'>
                                <div className='service-box'>
                                    <img src={MobileApp} alt='' />
                                    <div className='overlay'>
                                        <h2>Mobile App Development</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='service-box'>
                                    <img src={EommerceApp} alt='' />
                                    <div className='overlay'>
                                        <h2>E-commerce Development</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 pr-0'>
                                <div className='service-box'>
                                    <img src={AIApp} alt='' />
                                    <div className='overlay'>
                                        <h2>Ai Application</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 pr-0'>
                                <div className='service-box'>
                                    <img src={IOT} alt='' />
                                    <div className='overlay'>
                                        <h2>IOT Application</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='service-box'>
                                    <img src={Designservice} alt='' />
                                    <div className='overlay'>
                                        <h2>Designing Services</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='business-industry'>
                        <div className="sec-bottom">
                            <h2 className='sub-title'>We Serve A Variety Of 
                            <br/>
                            Businesses & Industries</h2>
                            <p className="top-title">A segment is a component of a business that generates its own revenues and creates its own product, product lines, or service offerings. Segments typically have discrete associated costs and operations.</p>
                        </div>
                        <div className='tools-list'>
                            <div className='tool-box'>
                                <img src={Ecommerce} />
                                <p>E Commerce</p>
                            </div>
                            <div className='tool-box'>
                                <img src={HealthCare} />
                                <p>Health Care</p>
                            </div>
                            <div className='tool-box'>
                                <img src={Government} />
                                <p>Government</p>
                            </div>
                            <div className='tool-box'>
                                <img src={Manufacturing} />
                                <p>Manufacturing</p>
                            </div>
                        </div>
                    </div>
                    <div id="contactform">
                        <div className="form-section">
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="expert-box letus-know">
                                        <h4>Let Us Know How We Can Help</h4>
                                        <div className="expert-phn">
                                            <p>With experience working with clients of various sizes,
                                                <br /> including startups and enterprise brands, we would
                                                <br /> love to discuss your project and explore how we can<br />
                                                provide value.</p>
                                        </div>
                                    </div>
                                
                                </Col>

                                <Col sm={12} md={6}>

                                    <div className="contact-form">
                                        <form>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder="" />
                                                        <label className="did-floating-label">Name</label>
                                                        
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " />
                                                        <label className="did-floating-label">Company</label>
                                                        
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <label class="did-floating-label" style={{ top: "-15px", color: "#152238" }}>Phone Number</label>
                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="IN"
                                                            id='MobileNo'
                                                            name='wspConPhone'
                                                            placeholder="Mobile Number"
                                                            value={value}
                                                            onChange={setValue}
                                                        />
                                                        
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " name=""  />
                                                        <label className="did-floating-label">Email</label>
                                                        
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={12}>
                                                    <div className="did-floating-label-content">
                                                        <input className="did-floating-input" type="text" placeholder=" " name="wspConMsg"  />
                                                        <label className="did-floating-label">Message</label>
                                                        
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={7}>
                                                    <div class="alert alert-success alert-dismissable">
                                                        <button type="button" class="close" aria-hidden="true"></button>
                                                            Message sent successfully.
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={5} className="submit-sec">
                                                    <a type="submit" className="btn submit-btn">Send Message</a>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
        </div>
    );
};
export default Services;