import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import People1 from '../../assets/images/team1.png';
import People2 from '../../assets/images/team2.png';
import People3 from '../../assets/images/team3.png';
import People4 from '../../assets/images/team4.png';
import People5 from '../../assets/images/team5.png';
import People6 from '../../assets/images/team6.png';
import People7 from '../../assets/images/team7.png';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const images = [
    {
        "url" : People1
    },
    {
        "url" : People2
    },
    {
        "url" : People3
    },
    {
        "url" : People4
    },
    {
        "url" : People5
    },
    {
        "url" : People6
    },
    {
        "url" : People7
    }
];

const TeamCarousel = () => {
  return (
    <Carousel
      itemClass="image-item"
      responsive={responsive}
      arrows={false}
    >
      {images.slice(0, 5).map(image => {
        return (
          <img
            draggable={false}
            src={image.url}
          />
        );
      })}
    </Carousel>
  );
};

export default TeamCarousel;
