import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactJs from '../assets/images/tools/react.png';
import Angular from '../assets/images/tools/angular.png';
import Javascript from '../assets/images/tools/javascript.png';
import Html from '../assets/images/tools/html.png';
import CSS from '../assets/images/tools/css.png';
import JQuery from '../assets/images/tools/jquery.png';
import Typescript from '../assets/images/tools/typescript.png';
import Bootstrap from '../assets/images/tools/bootstrap.png';
import Python from '../assets/images/tools/python.png';
import Ruby from '../assets/images/tools/ruby.png';
import Django from '../assets/images/tools/django.png';
import NodeJs from '../assets/images/tools/nodejs.png';
import Docker from '../assets/images/tools/docker.png';
import Flask from '../assets/images/tools/flask.png';
import AdobeXD from '../assets/images/tools/adobeXd.png';
import Illustrator from '../assets/images/tools/illustrator.png';
import InDesign from '../assets/images/tools/indesign.png';
import PremierPro from '../assets/images/tools/premierPro.png';
import Photoshop from '../assets/images/tools/photoshop.png';
import AfterEffect from '../assets/images/tools/afterEffect.png';
import Flutter from '../assets/images/tools/flutter.png';
import Swift from '../assets/images/tools/swift.png';
import Kotlin from '../assets/images/tools/kotlin.png';
import Aws from '../assets/images/tools/Aws.png';
import Lambda from '../assets/images/tools/lamda.png';
import S3 from '../assets/images/tools/s3.png';
import Dotnet from '../assets/images/tools/dotNet.png';
import Aspnet from '../assets/images/tools/aspNet.png';
import AppSync from '../assets/images/tools/appsync.png';
import Php from '../assets/images/tools/php.png';
import GraphQL from '../assets/images/tools/graphQl.png';
import MsSql from '../assets/images/tools/msSql.png';
import MySql from '../assets/images/tools/mysql.png';
import DynamoDB from '../assets/images/tools/dynamodb.png';
import PostgreSql from '../assets/images/tools/postgresql.png';
import AwsEc2 from '../assets/images/tools/aws-ec2.png';
import LightSail from '../assets/images/tools/lightsail.png';
import Docker2 from '../assets/images/tools/docker-2.png';
import Git from '../assets/images/tools/git.png';
import CICD from '../assets/images/tools/ci-cd.png';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Technology = () =>{
    const [value, setValue] = useState();
    return(
        <div className='technologies'>
            <div className="site-pages technology">
                <div className="container">
                    <div className="sec-bottom">
                        <p className="top-title">TECHNOLOGIES</p>
                        <h2 className='sub-title'>Our Tech Stack</h2>
                    </div>
                </div>
            </div>
            <div className='framework-tools'>
                <div className="container">
                    <div className="sec-bottom">
                        <p className="top-title">FRONT-END FRAMEWORKS AND TOOLS</p>
                        <h2 className='sub-title'>Building Modern and Responsive
                        <br/>
                        User Interfaces</h2>
                    </div>
                    <div className='tools-list'>
                        <div className='tool-box'>
                            <img src={ReactJs} />
                            <p>React Js</p>
                        </div>
                        <div className='tool-box'>
                            <img src={Angular} />
                            <p>Angular 2</p>
                        </div>
                        <div className='tool-box'>
                            <img src={JQuery} />
                            <p>JQuery</p>
                        </div>
                        <div className='tool-box'>
                            <img src={Html} />
                            <p>Html</p>
                        </div>
                        <div className='tool-box'>
                            <img src={CSS} />
                            <p>CSS</p>
                        </div>
                        <div className='tool-box'>
                            <img src={Javascript} />
                            <p>Javascript</p>
                        </div>
                        <div className='tool-box'>
                            <img src={Bootstrap} />
                            <p>Bootstrap</p>
                        </div>
                        <div className='tool-box'>
                            <img src={Typescript} />
                            <p>Typescript</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='backend-tools'>
                <div className="sec-bottom">
                    <p className="top-title">BACK-END STACKS</p>
                    <h2 className='sub-title'>Unlocking the Power of 
                    <br/>
                    Server-side Technologies</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={Python} />
                        <p>Python</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Ruby} />
                        <p>Ruby</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Django} />
                        <p>Django</p>
                    </div>
                    <div className='tool-box'>
                        <img src={NodeJs} />
                        <p>Node.Js</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Flask} />
                        <p>Flask</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Docker} />
                        <p>Docker</p>
                    </div>
                </div>
            </div>
            <div className='framework-tools'>
                <div className="sec-bottom">
                    <p className="top-title">DIGITAL DESIGN PLATFORM</p>
                    <h2 className='sub-title'>Create Stunning Designs with Ease</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={AdobeXD} />
                        <p>Adobe XD</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Illustrator} />
                        <p>Illustrator</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Photoshop} />
                        <p>Photoshop</p>
                    </div>
                    <div className='tool-box'>
                        <img src={PremierPro} />
                        <p>Premier Pro</p>
                    </div>
                    <div className='tool-box'>
                        <img src={InDesign} />
                        <p>InDesign</p>
                    </div>
                    <div className='tool-box'>
                        <img src={AfterEffect} />
                        <p>After Effect</p>
                    </div>
                </div>
            </div>
            <div className='backend-tools'>
                <div className="sec-bottom">
                    <p className="top-title">MOBILE APP</p>
                    <h2 className='sub-title'>Craft Your Dream Mobile
                    <br/>
                    Application with Our Team</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={ReactJs} />
                        <p>React Native</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Flutter} />
                        <p>Flutter</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Swift} />
                        <p>Swift</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Kotlin} />
                        <p>Kotlin</p>
                    </div>
                </div>
            </div>
            <div className='framework-tools'>
                <div className="sec-bottom">
                    <p className="top-title">SERVER SIDE</p>
                    <h2 className='sub-title'>Unlocking the Power of <br />Server-side Technologies</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={Aws} />
                        <p>AWS</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Lambda} />
                        <p>Lambda</p>
                    </div>
                    <div className='tool-box'>
                        <img src={AppSync} />
                        <p>AppSync</p>
                    </div>
                    <div className='tool-box'>
                        <img src={S3} />
                        <p>S3</p>
                    </div>
                    <div className='tool-box'>
                        <img src={GraphQL} />
                        <p>GraphQL</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Dotnet} />
                        <p>.Net Core</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Aspnet} />
                        <p>Asp.Net</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Php} />
                        <p>PHP</p>
                    </div>
                </div>
            </div>
            <div className='backend-tools'>
                <div className="sec-bottom">
                    <p className="top-title">DATABASE MANAGEMENT</p>
                    <h2 className='sub-title'>Optimize Your Operations with Our
                    <br/>
                    Robust Database Management</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={MsSql} />
                        <p>MS SQL</p>
                    </div>
                    <div className='tool-box'>
                        <img src={MySql} />
                        <p>Mysql</p>
                    </div>
                    <div className='tool-box'>
                        <img src={DynamoDB} />
                        <p>Dynamodb</p>
                    </div>
                    <div className='tool-box'>
                        <img src={PostgreSql} />
                        <p>Postgressql</p>
                    </div>
                </div>
            </div>
            <div className='framework-tools'>
                <div className="sec-bottom">
                    <p className="top-title">APPLICATION DEPLOYMENT</p>
                    <h2 className='sub-title'>Securely Deploy Your Applications <br />with Our Expert Team</h2>
                </div>
                <div className='tools-list'>
                    <div className='tool-box'>
                        <img src={AwsEc2} />
                        <p>AWS EC2</p>
                    </div>
                    <div className='tool-box'>
                        <img src={CICD} />
                        <p>CI/CD</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Git} />
                        <p>Git</p>
                    </div>
                    <div className='tool-box'>
                        <img src={LightSail} />
                        <p>LightSail</p>
                    </div>
                    <div className='tool-box'>
                        <img src={Docker2} />
                        <p>Docker</p>
                    </div>
                </div>
            </div>
            <div className="contactform">
                <div className="container">
                    <div className="form-section">
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="expert-box letus-know">
                                    <h4>Let Us Know How We Can Help</h4>
                                    <div className="expert-phn">
                                        <p>With experience working with clients of various sizes,
                                            <br /> including startups and enterprise brands, we would
                                            <br /> love to discuss your project and explore how we can<br />
                                            provide value.</p>
                                    </div>
                                </div>
                            
                            </Col>
                            <Col sm={12} md={6}>

                                <div className="contact-form">
                                    <form>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Name</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " />
                                                    <label className="did-floating-label">Company</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <label class="did-floating-label" style={{ top: "-15px", color: "#152238" }}>Phone Number</label>
                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="IN"
                                                        id='MobileNo'
                                                        name='wspConPhone'
                                                        placeholder="Mobile Number"
                                                        value={value}
                                                        onChange={setValue}
                                                    />
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name=""  />
                                                    <label className="did-floating-label">Email</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConMsg"  />
                                                    <label className="did-floating-label">Message</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                <div class="alert alert-success alert-dismissable">
                                                    <button type="button" class="close" aria-hidden="true"></button>
                                                        Message sent successfully.
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4} className="submit-sec">
                                                <a type="submit" className="btn submit-btn">Send Message</a>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Technology;