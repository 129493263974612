import React, { useState } from "react";
import { Link } from 'react-router-dom'
import "../assets/css/style.css";
import { Container, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import RadioButtonChecked from '../assets/images/radio_button_checked.svg';


const CareerDetail = () => {
    const [value, setValue] = useState();
    return (
        <div className="careers career-detail">
               <div className="career-banner">
               <div className="container">
                    <div className="sec-bottom">
                        <h2 className='sub-title'>UI/UX DESIGNER</h2>
                        <p>UI UX designers create the user interface for an app, website, or other interactive media. Their work includes collaborating with product managers and engineers to gather requirements from users before designing ideas that can be communicated using storyboards. They also process flows or sitemaps.</p>
                        <div className="button-sec">
                            <button type="submit" className="apply-btn"><a href="/Career-detail/#applynow">Apply Now</a></button>
                            <button type="submit" className="apply-btn">Share</button>
                        </div>
                    </div>
                </div>
            </div>
            <Col md={12}>
                <Row>
                    <Col md={12} className="p-0">
                        <div className="about-offers">
                            <div className="container">
                                <div className="oppenings" id="jobdetail">
                                    <div className="job">
                                        <h2>Senior UI/UX Designer</h2>
                                        <div className="requirements">
                                            <p className="designation">Designer</p>
                                            <p className="experience">Exp: 5+Years</p>
                                            <p className="posted-on">Posted on: 22/04/2023</p>
                                        </div>
                                        <ul>
                                            <h4>Are you excellent at doing the following:</h4>
                                            <li><img src={RadioButtonChecked} />Take a leadership role in shaping the overall design strategy, aligning it with business goals and user needs.</li>
                                            <li><img src={RadioButtonChecked} />Provide guidance and mentorship to junior designers, helping them grow their skills and expertise.</li>
                                            <li><img src={RadioButtonChecked} />Conduct in-depth user research and analysis to understand user behavior, needs, and pain points.</li>
                                            <li><img src={RadioButtonChecked} />Contribute to the development and maintenance of a design system to ensure consistency and scalability across products.</li>
                                            <li><img src={RadioButtonChecked} />Create user journey maps and identify opportunities to enhance the user experience at various touchpoints.</li>
                                            <li><img src={RadioButtonChecked} />Define and structure information in a logical and intuitive manner, ensuring easy navigation and content discoverability.</li>
                                        </ul>
                                        <ul>
                                            <h4>What will make you a perfect fit:</h4>
                                            <li><img src={RadioButtonChecked} />Extensive UI/UX Design Experience: With a wealth of experience in UI/UX design, there is a deep understanding and expertise in creating exceptional user experiences.</li>
                                            <li><img src={RadioButtonChecked} />Strong Portfolio: The portfolio showcases a diverse range of successful projects, demonstrating the ability to create visually appealing and user-friendly designs.</li>
                                            <li><img src={RadioButtonChecked} />Technical Proficiency: Highly proficient in industry-standard design tools and software, enabling effective translation of concepts into high-quality design deliverables.</li>
                                            <li><img src={RadioButtonChecked} />User-Centered Design Approach: A strong understanding of user-centered design principles and methodologies, ensuring intuitive, accessible, and user-aligned designs.</li>
                                            <li><img src={RadioButtonChecked} />Collaboration and Communication Skills: Excellent collaboration skills with cross-functional teams, effective communication of design concepts, and incorporation of feedback for impactful solutions.</li>
                                        </ul>
                                    </div>
                                </div>
                            
                                <div className="contact-form" id="applynow">
                                    <form>
                                        <h1>Apply Here</h1>
                                        <div className="apply-here-mobile">
                                            <h4>Let Us Know How We Can Help</h4>
                                            <p>With experience working with clients of various sizes, including startups and enterprise brands, we would love to discuss your project and explore how we can provide value.</p>
                                        </div>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=""/>
                                                    <label className="did-floating-label">Name</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="email" placeholder="" />
                                                    <label className="did-floating-label">Email</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <label class="did-floating-label" style={{ top: "-15px", color: "#152238" }}>Phone Number</label>
                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="IN"
                                                        id='MobileNo'
                                                        name=''
                                                        placeholder="Mobile Number"
                                                        value={value}
                                                        onChange={setValue}
                                                    />
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Linkedln Link</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Notice Period</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Current Location</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Why do you want to be a part of our team?</label>
                                                
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                <label className="did-floating-label">Attach Resume</label>
                                                    <label className="file-upload">
                                                        <div className="drag">
                                                            <p>Drag and drop files here</p>
                                                            <a className="apply-btn">Browse</a>
                                                        </div>
                                                        <input className="did-floating-input" type="file"/>
                                                    </label>
                                                
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12} className="submit-sec">
                                                <button type="submit" className="btn apply-btn">Submit</button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
    )
};

export default CareerDetail;