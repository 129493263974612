import React from "react";
import { Link, useLocation } from 'react-router-dom';
import "../assets/css/style.css";
import { Container, Row, Col } from 'react-bootstrap';
import Meplinks from '../assets/images/meplinks-page.png';
import Logo1 from '../assets/images/meplinks-logo.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

const PortfolioDetail = () => {
    const location = useLocation();

    return (
        <div className="portfolio-page">
             <div className="bread-crumbs">
                <nav>
                    <Link to="/"
                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        Home
                    </Link>
                    <span className="breadcrumb-arrow"></span>
                    <Link to="/portfolio"
                        className={location.pathname === "/portfolio" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        Portfolio
                    </Link>
                    <span className="breadcrumb-arrow"></span>
                    <Link
                        className={location.pathname.startsWith("/portfolio-detail") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        Meplinks
                    </Link>
                </nav>
            </div>
            <div className="works-list detail">
                <Row >
                    <Col md={6}>
                        <div className="left-folio">
                            <img src={Meplinks} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <Carousel responsive={responsive} showDots={true} autoPlay={false}>
                            <div className="works-item">
                                <img src={Logo1} className="logo" />
                                <p>The project is an online platform for selling surplus electromechanical inventory. The client aims to automate most of the functionalities, including finding suitable sellers, product uploads, listing for sales, easy checkout, and delivery processes.</p>
                            </div>
                            <div className="works-item">
                                <h4 className="challenge">THE CHALLENGE</h4>
                                <p>Our Challenge was to ensure an online platform for selling Surplus electromechanical inventory. Client comes with an idea and wants to automize most of the functionalities in the project starting from finding the suitable sellers, uploading their products, listing it for sales, easy checkout, and delivery process. An online platform for selling Surplus electromechanical inventory.</p>
                            </div>
                            <div className="works-item">
                                <h4 className="solution">OUR SOLUTION</h4>
                                <p>Our Challenge was to ensure an online platform for selling Surplus electromechanical inventory. Client comes with an idea and wants to automize most of the functionalities in the project starting from finding the suitable sellers, uploading their products, listing it for sales, easy checkout, and delivery process. An online platform for selling Surplus electromechanical inventory.</p>
                            </div>
                            <div className="works-item">
                                <h4 className="technical">TECHNICAL ASSESSMENT</h4>
                                <p>Web development technologies refer to the multitude of programming languages and tools that are used to produce dynamic and fully-featured websites and applications. Throughout this article, we'll talk about front-end and back-end technologies.</p>
                            </div>
                            
                        </Carousel>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default PortfolioDetail;