
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from './Components/Mainpage';
import Home from './Components/Home';
import ContactUs from './Components/ContactUs';
import Aboutus from './Components/AboutUs';
import Careers from './Components/Careers';
import CareerDetail from './Components/CareerDetail';
import Technology from './Components/Technology';
import Services from './Components/Services';
import Portfolio from './Components/Portfolio';
import PortfolioDetail from './Components/PortfolioDetail';
import Odoo from './Components/odoo'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<MainPage />} >
            <Route index element={<Home />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/aboutus' element={<Aboutus />} />
            <Route path='/careers' element={<Careers />} />
            <Route path='/career-detail' element={<CareerDetail />} />
            <Route path='/technologies' element={<Technology />} />
            <Route path='/services' element={<Services />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/portfolio-detail' element={<PortfolioDetail />} />
            <Route path='/odoo-service-provider' element={<Odoo />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
