import React, { useState } from "react";
import { Link } from 'react-router-dom'
import "../assets/css/style.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import Meplinks from '../assets/images/meplinks-page.png';
import Snapecookies from '../assets/images/snape-cookie.png';
import JesiRp from '../assets/images/jesi.png';
import HRMS from '../assets/images/hrms.png';
import SmartMi from '../assets/images/smartmi.png';
import VTS from '../assets/images/vts.png';
import Challenger from '../assets/images/challenger.png';
import BP from '../assets/images/beauty passion.png';

const Portfolio = () => {
    const [value, setValue] = useState();
    return (
        <div className="portfolio-page">
            <div className="site-pages port-tech">
                <div className="container">
                    <div className="sec-bottom">
                        <p className="top-title">OUR WORKS</p>
                        <h2 className='sub-title'>Designing Digital Products That Leave A
                        <br /> Lasting Impression On Your Clients</h2>
                    </div>
                </div>
            </div>
            <div className="works-list" id="Workslist">
                <div className="container">
                    <Row >
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={Snapecookies} />
                                <div className="work-inner">
                                    <h2>Snape cookie</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular <span></span> Flutter</h4>
                                    <p>Snapecookies.com offers a fun and innovative way to customize delicious cookies with personalized designs, quotes, and images. With a range of flavors to choose from, it’s the perfect destination for anyone looking to treat themselves or create a unique gift for a loved one.</p>
                                    
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={Meplinks} />
                                <div className="work-inner">
                                    <h2>MEPLINKS</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular <span></span> Flutter</h4>
                                    <p>An online platform for selling Surplus electromechanical inventory. Client comes with an idea and wants to automize most of the functionalities in the project starting from finding the suitable sellers, uploading their products, listing it for sales, easy checkout, and delivery process.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={SmartMi} />
                                <div className="work-inner">
                                    <h2>SmartMI</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular <span></span> Flutter</h4>
                                    <p>Is a go-to web and mobile solution CRM for seamlessly tracking and managing CRM business activities. Empowering companies and sales teams, it simplifies the handling of quotes, orders, employee schedules, and projects, ensuring a streamlined sales process.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={JesiRp} />
                                <div className="work-inner">
                                    <h2>Resource Portal</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular</h4>
                                    <p> A solution for streamlined employee management. Enhance productivity and maintain a systematic record of resource activities, ensuring optimal utilization of quality time without schedule delays. Helps to elevate company's efficiency with our powerful application.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={BP} />
                                <div className="work-inner">
                                    <h2>Beauty Passion</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular <span></span> Flutter</h4>
                                    <p>Beauty Passion is a brand that beats with beauty and elegance. It is an online e-commerce solution designed for customers in Kingdom of Saudi Arabia focusing beauty and personal care.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={Challenger} />
                                <div className="work-inner">
                                    <h2>Challenger</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular</h4>
                                    <p>CHALLENGER stands as a cutting-edge Manufacturing ERP system that seamlessly manages the entire Sales and Production lifecycle, from Customer Enquiry to Invoicing. This Windows & Web -based ERP is designed to navigate the intricacies of Sales & manufacturing operations efficiently.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={VTS} />
                                <div className="work-inner">
                                    <h2>Vehicle Tracking System (VTS)</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular</h4>
                                    <p>Specializes in advanced vehicle tracking systems that offer real-time tracking, monitoring, and analysis of fleets. Utilizing GPS technology and sophisticated software, the vehicle tracking solutions help businesses optimize routes, reduce fuel consumption, and improve overall fleet management.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                        <Col md={6} className="pb-5">
                            <div className="works-item">
                                <img src={HRMS} />
                                <div className="work-inner">
                                    <h2>HRMS</h2>
                                    <h4>UI/UX <span></span> Web Development <span></span> Mobile App <span></span> Angular</h4>
                                    <p>Elevate your HR experience with OpticalHR, where innovation meets efficiency. Revolutionize your workforce management effortlessly with our user-friendly and comprehensive cloud-based solution.</p>
                                </div>
                                <a className="read-more">Read More</a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div id="contactform">
                <div className="container">
                    <div className="form-section">
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="expert-box letus-know">
                                    <h4>Let Us Know How We Can Help</h4>
                                    <div className="expert-phn">
                                        <p>With experience working with clients of various sizes,
                                            <br /> including startups and enterprise brands, we would
                                            <br /> love to discuss your project and explore how we can<br />
                                            provide value.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="contact-form">
                                    <form>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder="" />
                                                    <label className="did-floating-label">Name</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " />
                                                    <label className="did-floating-label">Company</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <label class="did-floating-label" style={{ top: "-15px", color: "#152238" }}>Phone Number</label>
                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="IN"
                                                        id='MobileNo'
                                                        name='wspConPhone'
                                                        placeholder="Mobile Number"
                                                        value={value}
                                                        onChange={setValue}
                                                    />
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name=""  />
                                                    <label className="did-floating-label">Email</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConMsg"  />
                                                    <label className="did-floating-label">Message</label>
                                                    
                                                </div>
                                            </Col>
                                            <Col sm={12} md={7}>
                                                <div class="alert alert-success alert-dismissable">
                                                    <button type="button" class="close" aria-hidden="true"></button>
                                                        Message sent successfully.
                                                </div>
                                            </Col>
                                            <Col sm={12} md={5} className="submit-sec">
                                                <a type="submit" className="btn submit-btn">Send Message</a>
                                            </Col>
                                            
                                        </Row>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Portfolio;