import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { BsChevronRight } from "react-icons/bs";
import News1 from '../../assets/images/news1.png';
import News2 from '../../assets/images/news2.png';
import News3 from '../../assets/images/news3.png';

function NewsCarousel() {

  
 

  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };

  
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

  const newsdata = [
    {
        "url" : News1,
        "title" : "Next-Generation Technologies for Your Business: Links4Eng …",
        "date" : "14th May 2023",
        "detail" : "At Links4Engg.com, we understand that the world of technology is …"
    },
    {
        "url" : News2,
        "title" : "The Power of Technology at Your Fingertips: Links4Engg.com’",
        "date" : "14th May 2023",
        "detail" : "We believe that technology has the power to transform businesses …."
    },
    {
        "url" : News3,
        "title" : "Maximizing Efficiency and Productivity: LINKS4ENGG",
        "date" : "14th May 2023",
        "detail" : "In today’s fast-paced business environment, organizations need to …"
    },
    {
        "url" : News1,
        "title" : "Next-Generation Technologies for Your Business: Links4Eng …",
        "date" : "14th May 2023",
        "detail" : "At Links4Engg.com, we understand that the world of technology is …"
    },
    {
        "url" : News2,
        "title" : "The Power of Technology at Your Fingertips: Links4Engg.com’",
        "date" : "14th May 2023",
        "detail" : "We believe that technology has the power to transform businesses …."
    }
]


  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={false}
        swipeable={true}
        showDots
        customDot={<CustomDot />}
      >
          {newsdata.map(data => {
                return (
                    <div class="news-section">
                        <div class="image-box">
                            <img src={data.url} />
                        </div>
                        <div className="bottom-news">
                            <h5>{data.title}</h5>
                            <p className="date">{data.date}</p>
                            <p className="news-detail">{data.detail}</p>
                            <a className="read-more">Read More <BsChevronRight/></a>
                        </div>
                    </div>
                );
            })}
      </Carousel>
    </>
  );
}

export default NewsCarousel


