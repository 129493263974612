import React, { useState } from 'react';
import { Row, Accordion, Col } from 'react-bootstrap'
import '../../assets/css/footer.css';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-white.png';
import Facebook from '../../assets/images/facebook.svg';
import Instagram from '../../assets/images/instagram.svg';
import Linkedin from '../../assets/images/linkedin.svg';
import OdooLogo from '../../assets/images/odoo/odoo-footer-logo.svg'


function Footer() {




    return (
        <footer className="mainfooter">
            <div className="bottom-footer">
                <div className='container'>
                    <nav className="toplink">
                        <div className='d-flex justify-content-between flex-wrap'>
                            <div className='footer-address-col'>
                                <div className="logo-wrapper">
                                    <Link className="anchor logo-anchor" to='/'>
                                        <picture>
                                            <img
                                                srcSet={Logo}
                                                alt="links 4 engineering"
                                            />
                                        </picture>
                                    </Link>
                                    <div className='company-address'>
                                        <p><span>LINKS4ENGG</span> INDIA PVT. LTD<br /> 98/2525, PR Complex, Pullukadu Road,<br /> Kulathoor PO Trivandrum, Kerala,<br /> India - 695583</p>
                                        <p>+91 471 3592209 <span className='mx-2'> | </span> +91 7306889096</p>
                                        <p>info@links4engg.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-col'>
                                <ul className="footernav">
                                    <li className='footerlink footer-title'>Company</li>
                                    <li className="footerlink">
                                        <Link to='/aboutus' onClick={() => { window.scroll(0, 0); }}>About Us</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/services' onClick={() => { window.scroll(0, 0); }}>Services</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/portfolio' onClick={() => { window.scroll(0, 0); }}>Portfolio</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/technologies' onClick={() => { window.scroll(0, 0); }}>Technologies</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/careers' onClick={() => { window.scroll(0, 0); }}>Career</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/social' onClick={() => { window.scroll(0, 0); }}>Social Responsibilities</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footer-col'>
                                <ul className="footernav">
                                    <li className='footerlink footer-title'>Services</li>
                                    <li className="footerlink">
                                        <Link to='/' onClick={() => { window.scroll(0, 0); }}>Web Development</Link>
                                    </li>
                                    <li className="footerlink">
                                        <a href='/'> AI Application</a>
                                    </li>
                                    <li className="footerlink">
                                        <a href='/'>Designing Services</a>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/' onClick={() => { window.scroll(0, 0); }}>Data Warehousing</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/' onClick={() => { window.scroll(0, 0); }}>IOT & Automation</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/' onClick={() => { window.scroll(0, 0); }}>Testing & QA Services</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/' onClick={() => { window.scroll(0, 0); }}>Mobile App Development</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footer-col'>
                                <ul className="footernav">
                                    <li className='footerlink footer-title'>Support</li>
                                    <li className="footerlink">
                                        <Link to='/contactus' onClick={() => { window.scroll(0, 0); }}>Contact Us</Link>
                                    </li>
                                    <li className="footerlink">
                                        <a href='/'> Privacy Policy</a>
                                    </li>
                                    <li className="footerlink">
                                        <a href='/'> Cookies Policy</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='footer-col'>
                                <ul className="footernav">
                                    <li className='footerlink footer-title'>Partners</li>
                                    <li className="footerlink">
                                        <img src={OdooLogo} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='below-footer'>
                <div className='container'>
                    <nav className="toplink region">
                        <ul className="footernav regionnav">
                            <li className="regionlink copyright">
                                <span>© 2023 LINKS4ENGG. All rights are reserved</span>
                            </li>
                            <li className='followlink'>
                                <a href='https://www.facebook.com' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Facebook}
                                            alt="facebook"
                                        />
                                    </picture>
                                </a>
                            </li>
                            <li className='followlink'>
                                <a href='https://www.instagram.com' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Instagram}
                                            alt="Instagram"
                                        />
                                    </picture>
                                </a>
                            </li>
                            <li className='followlink'>
                                <a href='https://www.linkedin.com' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Linkedin}
                                            alt="Linkedin"
                                        />
                                    </picture>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default Footer