import React from "react";
import { Link } from 'react-router-dom'
import "../assets/css/style.css";
import { Container, Row, Col } from 'react-bootstrap';
import Vision from '../assets/images/vision.png';
import Value from '../assets/images/value.png';
import Mission from '../assets/images/mission.png';
import Quality from '../assets/images/quality.png';
import Person1 from '../assets/images/person1.png';
import Person2 from '../assets/images/person2.png';
import Person3 from '../assets/images/person3.png';
import Person4 from '../assets/images/person4.png';
import Person5 from '../assets/images/person5.png';
import EqualPay from '../assets/images/equal-pay.png';
import Flexibility from '../assets/images/flexibility.png';
import Growth from '../assets/images/growth.png';
import Favicon from '../assets/images/favicon.png';
import Healthcare from '../assets/images/health-care.svg';
import Insurance from '../assets/images/insurance.svg';
import Training from '../assets/images/training.svg';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { BsFillCaretRightFill, BsFillCaretDownFill } from "react-icons/bs";
import People1 from '../assets/images/team1.png';
import People2 from '../assets/images/team2.png';
import People3 from '../assets/images/team3.png';
import People4 from '../assets/images/team4.png';
import People5 from '../assets/images/team5.png';
import People6 from '../assets/images/team6.png';
import People7 from '../assets/images/team7.png';
import Inspire from '../assets/images/inspire.png';
import TeamCarousel from "./common/TeamCarousel";
import NewsCarousel from "./common/NewsCarousel";


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

const Aboutus = () => {
    return (
        <div className="about-us">
            <div className="site-pages about-us">
                <div className="container">
                    <div className="sec-bottom">
                        <p className="top-title">ABOUT US</p>
                        <h2 className='sub-title'>Lead By Innovation & Technology</h2>
                    </div>
                </div>
            </div>
            <Col md={12}>
                <Row>
                    <Col md={12} className="p-0">
                            <div className="vision-mission">
                                <div className="container">
                                    <Row>
                                        <Col md={6}>
                                            <div className="vision-box">
                                                <img src={Vision} />
                                                <div className="vision-text">
                                                    <h2>Vision</h2>
                                                    <p>To provide Technology – based Innovative and Dependable Services & Products that Accelerate Our Clients Success by Enabling them to Drive Efficiencies and focus on their core business.</p>
                                                </div>
                                            </div>
                                            <div className="vision-box">
                                                <img src={Value} />
                                                <div className="vision-text">
                                                    <h2>Value</h2>
                                                    <p>A values statement lists the core principles that guide and direct the organization and its culture. In a values-led organization, the values create a moral compass for the organization and its employees.</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="right-side">
                                                <div className="vision-box">
                                                    <img src={Mission} />
                                                    <div className="vision-text">
                                                        <h2>Mission</h2>
                                                        <p>To achieve our goal by providing distinguished software services in the global markets by constant innovation in technology. To achieve our goal by providing distinguished software services.</p>
                                                    </div>
                                                </div>
                                                <div className="vision-box">
                                                    <img src={Quality} />
                                                    <div className="vision-text">
                                                        <h2>Quality</h2>
                                                        <p>To achieveQuality has become a way of life – the standards by which the best are separated from the rest. We are committed to our vision to provide quality solutions to our clients.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={12}>
                        <div className="about-teams">
                            <p className="sec-top-title">DIRECTORS TEAM</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Driving Success
                                <br /> through Leadership</h2>
                                <p>Our experienced and dedicated leadership team at LINKS4ENGG is committed to guiding the company towards growth and innovation, while staying true to our core values. With their expertise and vision, 
                                    we are confident in our ability to deliver outstanding results for our clients and stakeholders.</p>
                            </div>
                            <div className="people">
                                <Row>
                                    <Col md={3} sm={6} className="pl-0">
                                        <div className="person-box">
                                                <div className="image-container">
                                                    <img src={Person1}/>
                                                    <div className="after">
                                                        <div className="img-box">
                                                            <img src={Favicon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="person-title">
                                                    <h2>PRADEEP RAJAN</h2>
                                                    <p>CEO Founder</p>
                                                </div>
                                        </div>
                                    </Col>
                                    <Col md={3} sm={6} className="pl-0">
                                        <div className="person-box">
                                            <div className="image-container">
                                            <img src={Person2} />
                                            <div className="after">
                                                <div className="img-box">
                                                    <img src={Favicon} />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="person-title">
                                                <h2>BINDHU</h2>
                                                <p>Director of Development & Operations</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3} sm={6} className="pl-0">
                                        <div className="person-box">
                                            <div className="image-container">
                                                <img src={Person3} />
                                                <div className="after">
                                                    <div className="img-box">
                                                        <img src={Favicon} />
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="person-title">
                                                    <h2>ALBIN SATHYADHAS</h2>
                                                    <p>Director of Development & Operations</p>
                                                </div>
                                        </div>
                                    </Col>
                                    <Col md={3} sm={6} className="pl-0">
                                        <div className="person-box">
                                        <div className="image-container">
                                            <img src={Person4} />
                                            <div className="after">
                                                <div className="img-box">
                                                    <img src={Favicon} />
                                                </div>
                                            </div>
                                        </div>
                                            <div className="person-title">
                                                <h2>ARUN GOPALAKRISHNAN</h2>
                                                <p>Director of Sales & Marketing</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3} sm={3} className="pl-0">
                                        <div className="person-box mentor">
                                            <img src={Person5} />
                                        </div>
                                    </Col>
                                    <Col md={9} sm={9} className="pl-0">
                                        <div className="person-box mentor">
                                            <div className="person-title">
                                                <h2>ANIL SARASWATHI</h2>
                                                <p className="mentor-title">Meet Our Mentors</p>
                                                <p>With over [X] years of experience in the IT field, [Mentor’s Name] is a visionary leader who has played a significant role in steering the industry’s progress. Known for their deep understanding of [specific area of expertise], [Mentor’s Name] has been at the forefront of [mention noteworthy achievements or projects]. Their dedication to sharing knowledge and nurturing talent has made them a cornerstone of our organization’s growth.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={12}>
                        <div className="container">
                            <div className="about-teams">
                                <p className="sec-top-title">OUR TEAM</p>
                                <div className="sec-bottom">
                                    <h2 className="sec-title">Collaborating for Excellence</h2>
                                    <p>Our passionate and highly skilled team at LINKS4ENGG believes in a collaborative approach to deliver excellence in every project. With ongoing training and development opportunities, our team works together seamlessly to bring innovative ideas and solutions to the table. With our diverse talents and expertise, we exceed our clients’ expectations and achieve outstanding results.</p>
                                </div>
                                <div className="designations">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="projecthead">
                                    <Row>
                                        <Col sm={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                            <Nav.Link  eventKey="projecthead">Project head <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="designers">Designers <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="developers">Developers <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="qa-analyst">QA Analyst <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane  eventKey="projecthead">
                                                <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Project Head</h3>
                                                        <hr />
                                                        <p>A Project head plays a crucial role in guiding and coordinating a group of individuals towards a common goal, fostering collaboration, and maximizing the team’s potential for success.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>02</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="designers">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>UI/UX Designers</h3>
                                                        <hr />
                                                        <p>UI/UX designers are vital in creating user-centric digital experiences. They blend creativity and functionality to design intuitive interfaces, enhance user satisfaction, and ensure seamless interactions between users and digital products or services.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>03</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="developers">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Developers</h3>
                                                        <hr />
                                                        <p>Developers are skilled professionals who bring ideas to life through coding. They possess technical expertise, problem-solving abilities, and a passion for innovation, enabling them to build functional and efficient software solutions.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>23</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="qa-analyst">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Qa Analyst</h3>
                                                        <hr />
                                                        <p>QA analysts play a crucial role in ensuring the quality and reliability of software. They meticulously test and identify bugs, providing valuable insights to improve the overall user experience and product functionality.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>05</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                    </Tab.Container>
                                </div>
                                {/* Mobile view */}
                                <div className="designations mobile-view">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="projecthead">
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                            <Nav.Link eventKey="projecthead">Project head <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Tab.Content>
                                            <Tab.Pane eventKey="projecthead">
                                                <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Project Head</h3>
                                                        <hr />
                                                        <p>A Project head plays a crucial role in guiding and coordinating a group of individuals towards a common goal, fostering collaboration, and maximizing the team’s potential for success.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>02</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                            <Nav.Item>
                                            <Nav.Link eventKey="designers">Designers <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Tab.Content>
                                            <Tab.Pane eventKey="designers">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>UI/UX Designers</h3>
                                                        <hr />
                                                        <p>UI/UX designers are vital in creating user-centric digital experiences. They blend creativity and functionality to design intuitive interfaces, enhance user satisfaction, and ensure seamless interactions between users and digital products or services.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>03</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            </Tab.Content>
                                            <Nav.Item>
                                            <Nav.Link eventKey="developers">Developers <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Tab.Content>
                                                
                                            <Tab.Pane eventKey="developers">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Developers</h3>
                                                        <hr />
                                                        <p>Developers are skilled professionals who bring ideas to life through coding. They possess technical expertise, problem-solving abilities, and a passion for innovation, enabling them to build functional and efficient software solutions.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>23</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            </Tab.Content>
                                            <Nav.Item>
                                            <Nav.Link eventKey="qa-analyst">QA Analyst <BsFillCaretDownFill /></Nav.Link>
                                            </Nav.Item>
                                            <Tab.Content>
                                            <Tab.Pane eventKey="qa-analyst">
                                            <div className="designation-descript">
                                                    <div className="left-desc">
                                                        <h3>Qa Analyst</h3>
                                                        <hr />
                                                        <p>QA analysts play a crucial role in ensuring the quality and reliability of software. They meticulously test and identify bugs, providing valuable insights to improve the overall user experience and product functionality.</p>
                                                    </div>
                                                    <div className="total-employee">
                                                        <p>05</p>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            </Tab.Content>
                                        </Nav>
                                    </Tab.Container>
                                </div>

                                <div className="people">
                                    <Row>
                                        <Col md={4} className="pr-1">
                                            <img src={People1} />
                                        </Col>
                                        <Col md={3} className="pr-1 pl-1">
                                            <img src={People4} />
                                        </Col>
                                        <Col md={3} className="pr-1 pl-1">
                                            <img src={People2} />
                                        </Col>
                                        <Col md={2} className="pl-1">
                                            <img src={People3} />
                                        </Col>
                                    </Row>
                                    <Row className="bottom-team pt-2">
                                        <Col md={7} className="pr-1">
                                            <img src={People6} />
                                        </Col>
                                        <Col md={3} className="pr-1 pl-1">
                                            <img src={People7} />
                                        </Col>
                                        <Col md={2} className="pr-1 pl-1">
                                            <img src={People5} />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="people mobile-view">
                                    <TeamCarousel />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="inspiring">
                        <img src={Inspire} />
                        <div className="container">
                        <h2>Inspiring Women
                            <br />
                            <span>to Innovate and Transform the Tech Industry</span>
                        </h2>
                        </div>
                    </div>
                </Row>
                <Row className="excellence">
                    <Col md={12}>
                        <div className="container">
                            <div className="about-teams excellence">
                                <p className="sec-top-title">OUR SOCIAL RESPONSIBILITY</p>
                                <div className="sec-bottom">
                                    <h2 className="sec-title">Collaborating for Excellence</h2>
                                    <p>Social responsibility is an ethical theory in which individuals are accountable for fulfilling their civic duty, and the actions of an individual must benefit the whole of society. In this way, there must be a balance between economic growth and the welfare of society and the environment.</p>
                                </div>
                                <div className="qualities">
                                    <div className="quality-inner">
                                        <Row>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={EqualPay} />
                                                        <h2>EQUAL PAY</h2>
                                                    </div>
                                                    <p>Pay equality, or equal pay for equal work, refers to the requirement that men and women be paid the same if performing the same job in the same organization. For example, a female electrician must be paid the same as a male electrician in the same organization.</p>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={Flexibility} />
                                                        <h2>FLEXIBILITY</h2>
                                                    </div>
                                                    <p>Workplace flexibility embraces the idea employees can be productive no matter when or where they perform their work. Rather than enforcing a rigid workplace environment or to schedule, workplace flexibility acknowledges individual needs.</p>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={Growth} />
                                                        <h2>GROWTH</h2>
                                                    </div>
                                                    <p>Organizational growth is always by something many businesses strive for, regardless of their size. It has the potential to provide companies with a variety of benefits, including greater ability to withstand market fluctuations,</p>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={Healthcare} />
                                                        <h2>HEALTH CARE</h2>
                                                    </div>
                                                    <p>Medical records and technicians, disgnostic on medical sonographers, radiologic technologists and technicians, dental  hygienists, nursing aids, home health aids, building cleaning workers, dental assistants, medical assistants, and personal and home.</p>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={Insurance} />
                                                        <h2>INSURANCE</h2>
                                                    </div>
                                                    <p>A job insurance policy provides financial aid to the  policyholder and the family for a certain duration in case the policy holder, also the breadwinner, loses his/ her job. The person is eligible to get these compensation if the job loss takesplace due to reasons mentioned in the policy.</p>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="quality-text">
                                                    <div className="title">
                                                        <img src={Training} />
                                                        <h2>TRAINING</h2>
                                                    </div>
                                                    <p>On the job training is a form of training provided at the workplace. During the training, employees are familiarized with working environment they will become part of. Employees also get a hands-on experience using machinery, equipment, tools, materials, etc. </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="news-events">
                                    <p className="sec-top-title">NEWS & EVENTS</p>
                                    <div className="sec-bottom">
                                        <h2 className="sec-title">Innovative news. Exciting events.<br /> Stay connected</h2>
                                    </div>
                                    <NewsCarousel />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>


        </div>
    )
};

export default Aboutus;