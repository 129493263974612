import React from "react";
import { Link } from 'react-router-dom'
import "../assets/css/style.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import Angular from '../assets/images/tools/01-Angualr.png';
import Dotnet from '../assets/images/tools/01-.net.png';
import Aws from '../assets/images/tools/01-Amazon_Web_Services.png';
import Flutter from '../assets/images/tools/01-flutter.png';
import Laravel from '../assets/images/tools/01-Laravel.png';
import Bootstrap from '../assets/images/tools/01-Bootstrap.png';
import Government from '../assets/images/monitor.png';
import Logo1 from '../assets/images/meplinks-logo.png';
import Logo2 from '../assets/images/belaoryx-logo.png';
import Meplinks from '../assets/images/meplinks-home.png';
import Belaoryx from '../assets/images/berolex-home.png';
import VTS from '../assets/images/vts-home.png';
import VTSlogo from '../assets/images/vts-logo.png';
import Jesi from '../assets/images/jesi-home.png';
import RPlogo from '../assets/images/jesi-logo.png';
import SmartMi from '../assets/images/smartmi-home.png';
import SmartMilogo from '../assets/images/smartmi-logo.png';
import HRMS from '../assets/images/hrms-home.png';
import Hrmslogo from '../assets/images/hrms-logo.png';
import MobileApp from '../assets/images/mobile-app.png';
import WebApp from '../assets/images/web-app.png';
import AIApp from '../assets/images/AI-app.png';
import Designservice from '../assets/images/design-service.png';

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

const Home = () => {
    return (
        <div className="home-page">
            <div className="home-banner">
                <div className="overlay">
                    <div className="container">
                        <div className="sec-bottom">
                            <h2 className='sub-title'>Empowering Innovation 
                            <br/>
                            Enter A New Era Of
                            <br/>
                            Technology
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-about-us">
                <div className="container">
                    <Row>
                        <Col md={5}>
                            <div className="right-sec"></div>
                        </Col>
                        <Col md={7} className="p-0">
                            <div className="home-about">
                                <div className="sec-bottom">
                                    <p className="top-title">ABOUT US</p>
                                    <h2 className='sub-title'>Software Solution
                                    <br />Provider</h2>
                                </div>
                                <div className="about-us">
                                    <p>At LINKS4ENGG, we offer custom software solutions designed to streamline your business processes and increase productivity. 
                                        Our team of experts can create tailored solutions that meet your unique business needs, 
                                        whether you require a custom application, a web-based solution, or a mobile app. 
                                        <br/>
                                        <br/>
                                        We use the latest tools and technologies to develop robust, scalable, and secure software 
                                        solutions that align with your business goals and objectives. Choose LINKS4ENGG for 
                                        reliable and effective software solutions that drive your business forward.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-services">
                <div className="container">
                    <Row>
                        <Col md={5}>
                            <div className="sevice-left">
                                <div className="row">
                                    <div className="col-md-6 pr-2">
                                        <div className='service-box'>
                                            <img src={WebApp} alt='' />
                                            <div className='overlay'>
                                                <h2>Web Development</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-2">
                                        <div className='service-box' style={{marginTop:"-15px"}}>
                                            <img src={MobileApp} alt='' />
                                            <div className='overlay'>
                                                <h2>Mobile App Development</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pr-2 pt-3">
                                        <div className='service-box'>
                                            <img src={Designservice} alt='' />
                                            <div className='overlay'>
                                                <h2>Designing Services</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-2 pt-3">
                                        <div className='service-box' style={{marginTop:"-15px"}}>
                                            <img src={AIApp} alt='' />
                                            <div className='overlay'>
                                                <h2>Ai Application</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="home-service">
                                <div className="sec-bottom">
                                    <p className="top-title">OUR SERVICES</p>
                                    <h2 className='sub-title'>Innovative Business 
                                    <br />Optimization Solutions</h2>
                                </div>
                                <div className="service-desc">
                                    <p>
                                    Our innovative business optimization solutions are designed to help your unique business needs. 
                                    From process automation to supply chain optimization, our team of experts works with you to identify 
                                    areas for improvement and implement cutting-edge solutions. Contact us today to take your business to the next level.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-commitment">
                <div className="container">
                    <Row>
                        <Col md={5} className="py-0">
                            <div className="home-commit">
                                <div className="sec-bottom">
                                    <p className="top-title">WHY LINKS4ENGG</p>
                                    <h2 className='sub-title'>Our Commitment 
                                    <br />to Your Success</h2>
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="right-sec">
                                <div className="commit-right">
                                    <div className="commmit-box">
                                        <h2>Expertise</h2>
                                        <p>We have a team of experienced professionals who are experts in website design and development. 
                                            Our expertise ensures that we deliver high-quality websites that are tailored to your needs.</p>
                                    </div>
                                    <div className="commmit-box">
                                        <h2>Customer Support</h2>
                                        <p>We provide excellent customer support throughout the process. Our team is always available to answer any questions you may have and to provide support even after your website is live.</p>
                                    </div>
                                    <div className="commmit-box">
                                        <h2>Customer Support</h2>
                                        <p>We provide excellent customer support throughout the process. Our team is always available to answer any questions you may have and to provide support even after your website is live.</p>
                                    </div>
                                    <div className="commmit-box">
                                        <h2>Customer Support</h2>
                                        <p>We provide excellent customer support throughout the process. Our team is always available to answer any questions you may have and to provide support even after your website is live.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-portfolio">
                <div className="container">
                    <Row>
                        <Col md={12}>
                            <div className="portfolio">
                                <div className="sec-bottom">
                                    <p className="top-title">OUR PORTFOLIO</p>
                                    <h2 className='sub-title'>Our Recent Projects</h2>
                                </div>
                                <div className="portfolio-desc">
                                    <p>
                                    Our expert team specializes in web and mobile app development, design, and branding. We pride ourselves on delivering innovative solutions tailored to our clients’ needs, using the latest technologies and best practices.
                                    </p>
                                </div>
                                <div className="portfolio-slider">
                                    <Carousel responsive={responsive}>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={Logo1} alt="Meplinks"/>
                                                <p>Streamlining MEP businesses by selling excess inventory, acquiring discounted equipment, and promoting environmental sustainability.</p>
                                                <img src={Meplinks} alt="Meplinks" style={{width:"100%"}} />  
                                                <a href="www.meplinks.com">www.meplinks.com</a>                                          
                                            </div>
                                        </div>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={Logo2} alt="Belaoryx"/>
                                                <p>Discover premium beauty products for skincare, makeup, and fragrances at Belaoryx Cosmetics – enhancing your natural beauty with luxury.</p>
                                                <img src={Belaoryx} alt="Belaoryx" style={{width:"100%"}} />  
                                                <a href="www.belorex.com">www.belorex.com</a>                                            
                                            </div>
                                        </div>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={VTSlogo} alt="Vehicle Tracking System (VTS)"/>
                                                <p>Specializes in advanced vehicle tracking systems that offer real-time tracking, monitoring, and analysis of fleets. Utilizing GPS technology and sophisticated software, the vehicle tracking solutions help businesses optimize routes, reduce fuel consumption, and improve overall fleet management.</p>
                                                <img src={VTS} alt="Vehicle Tracking System (VTS)" style={{width:"100%"}} />  
                                                <a href="https://cgvts.cgstate.gov.in/">https://cgvts.cgstate.gov.in</a>                                          
                                            </div>
                                        </div>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={RPlogo} alt="Resource portal"/>
                                                <p> A solution for streamlined employee management. Enhance productivity and maintain a systematic record of resource activities, ensuring optimal utilization of quality time without schedule delays. Helps to elevate company's efficiency with our powerful application.</p>
                                                <img src={Jesi} alt="Resource Portal" style={{width:"100%"}} />  
                                                <a href=""></a>                                            
                                            </div>
                                        </div>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={SmartMilogo} alt="SmartMi"/>
                                                <p> Is a go-to web and mobile solution CRM for seamlessly tracking and managing CRM business activities. Empowering companies and sales teams, it simplifies the handling of quotes, orders, employee schedules, and projects, ensuring a streamlined sales process.</p>
                                                <img src={SmartMi} alt="SmartMi" style={{width:"100%"}} />  
                                                <a href=""></a>                                          
                                            </div>
                                        </div>
                                        <div className="img-item">
                                            <div className="one-side">
                                                <img className="logo" src={Hrmslogo} alt="HRMS"/>
                                                <p>Elevate your HR experience with OpticalHR, where innovation meets efficiency. Revolutionize your workforce management effortlessly with our user-friendly and comprehensive cloud-based solution.</p>
                                                <img src={HRMS} alt="HRMS " style={{width:"100%"}} />  
                                                <a href=""></a>                                            
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-technologies">
                <div className="container">
                    <Row>
                        <Col md={12}>
                            <div className="home-tech">
                                <div className="sec-bottom">
                                    <p className="top-title">WHY LINKS4ENGG</p>
                                    <h2 className='sub-title'>Our Core Technologies</h2>
                                </div>
                                <div className='tools-list'>
                                    <div className='tool-box normal'>
                                        <img src={Angular} />
                                    </div>
                                    <div className='tool-box normal'>
                                        <img src={Dotnet} />
                                    </div>
                                    <div className='tool-box normal'>
                                        <img src={Aws} />
                                    </div>
                                    <div className='tool-box normal'>
                                        <img src={Flutter} />
                                    </div>
                                    <div className='tool-box normal'>
                                        <img src={Laravel} />
                                    </div>
                                    <div className='tool-box normal'>
                                        <img src={Bootstrap} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-clients">
                <div className="container">
                    <Row>
                        <Col md={12}>
                            <div className='tools-list'>
                                <div className='tool-box digitaz'></div>
                                <div className='tool-box Bp'></div>
                                <div className='tool-box jersey-group'></div>
                                <div className='tool-box jersey'></div>
                                <div className='tool-box belaoryx'></div>
                                <div className='tool-box qadnet'></div>
                                <div className='tool-box meplinks'></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="home-support">
                <div className="container">
                    <Row>
                        <Col md={7}>
                            <div className="home-service">
                                <div className="sec-bottom">
                                    <p className="top-title">LINKS4ENGG SUPPORTS AT</p>
                                    <h2 className='sub-title'>Our Segments</h2>
                                </div>
                                <div className="service-desc">
                                    <p>
                                    At LINKS4ENGG, we specialize in designing, developing and testing websites for a variety of industries. Our team expertise includes creating customized solutions that help businesses establish a strong online presence and achieve their goals. Whether you're a small startup or a large corporation, our team of experienced and professionals is dedicated to delivering exceptional results that meet your unique needs.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="sevice-left">
                                <div className="service-box">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="service-list">
                                                <img src={Government} />
                                                <h2>Government</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="service-list" style={{marginTop:"55px"}}>
                                                <img src={Government} />
                                                <h2>Manufacturing</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="service-list">
                                                <img src={Government} />
                                                <h2>E-Commerce</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{marginTop:"55px"}}>
                                            <div className="service-list">
                                                <img src={Government} />
                                                <h2>Health Care</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="service-list">
                                                <img src={Government} />
                                                <h2>Transportation</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{marginTop:"55px"}}>
                                            <div className="service-list">
                                                <img src={Government} />
                                                <h2>Food</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

export default Home;